import { Link } from 'gatsby'
import React, { Component, LegacyRef, MouseEvent, Ref, RefObject } from 'react'
import '../style/main.scss'
import { IoMdMenu } from 'react-icons/io';
import Footer from './Footer';
import Helmet from 'react-helmet'

export default class Layout extends Component<{ title?: string, active: number, forcedFooter?: boolean }, {isTop: boolean, mobileMenu: boolean}> {
    private isHome = !this.props.title;
    private Header = React.createRef() as RefObject<HTMLDivElement>;

    constructor(props) {
        super(props);

        this.onScroll = this.onScroll.bind(this);

        this.state = {
            isTop: this.isHome,
            mobileMenu: false
        }
    }

    onScroll(e: Event) {
        if(window.scrollY != 0 && this.Header.current.classList.contains("top")) {
            this.Header.current.classList.remove("top");
            this.setState({ isTop: false });
        } else if(window.scrollY == 0 && !this.Header.current.classList.contains("top")) {
            this.Header.current.classList.add("top");
            this.setState({ isTop: true });
        }
    }

    componentDidMount() {
        if(this.isHome) document.body.onscroll = this.onScroll;
    }

    componentWillUnmount() {
        document.body.onscroll = null;
    }

    render() {
        return (
            <div id="layout">
                <Helmet title={"KaPaBo" + (this.props.title ? ` | ${this.props.title}` : "")} defer={false}>
                    <meta name="description" content="Vi är det lilla fastighetsbolaget som äger och förvaltar bostäder i Burträsk och Ånäset. Med goda kommunikationsmöjligheter till Skellefteå respektive Umeå möjliggörs ett bra alternativ till ett något mer avskilt boende."/>
                    <meta name="og:title" property="og:title" content="Vi är det lilla fastighetsbolaget som äger och förvaltar bostäder i Burträsk och Ånäset. Med goda kommunikationsmöjligheter till Skellefteå respektive Umeå möjliggörs ett bra alternativ till ett något mer avskilt boende."/>
                    <meta name="twitter:card" content="Vi är det lilla fastighetsbolaget som äger och förvaltar bostäder i Burträsk och Ånäset. Med goda kommunikationsmöjligheter till Skellefteå respektive Umeå möjliggörs ett bra alternativ till ett något mer avskilt boende."/>
                </Helmet>
                <header style={{position: this.isHome ? "fixed" : "relative"}} ref={this.Header} className={this.isHome ? "top" : ""}>
                    <div className="p-margin" style={{ height: "100%" }}>
                        <Link style={{display: "inline-block"}} to="/"><img id="logo" src={`/static/images/logos/small-header-logo.png`} /></Link>
                        <ul id="desktopMenu" className="v-center">
                            {[
                                (<Link key="1" to="/">Hem</Link>),
                                (<Link key="2" to="/fastigheter">Fastigheter</Link>),
                                (<Link key="3" to="/for-hyresgaster">För hyresgäster</Link>),
                                (<Link key="4" to="/aktivitet">Aktivitet</Link>),
                                (<Link key="5" to="/kontakt">Kontakt</Link>)
                            ].map(e => {
                                return (<li key={e.key} id={(e.key == this.props.active) ? "selected" : ""}>{e}</li>)
                            })}
                        </ul>
                        <div id="mobileMenu" className="v-center">
                            <IoMdMenu style={{color: this.state.isTop ? "#FFF" : "#222f3e"}} onClick={() => this.setState({ mobileMenu: !this.state.mobileMenu })}/>
                        </div>
                        <div style={{display: this.state.mobileMenu ? "block" : "none"}} className="popup-menu">
                                <div className="popup-menu__mini-header p-margin">
                                    <div id="mobileMenu" className="v-center">
                                        <IoMdMenu onClick={() => this.setState({ mobileMenu: !this.state.mobileMenu })}/>
                                    </div>
                                </div>
                                <ul onClick={() => this.setState({ mobileMenu: false })} className="popup-menu__list v-center">
                                    {[
                                        (<Link key="1" to="/">Hem</Link>),
                                        (<Link key="2" to="/fastigheter">Fastigheter</Link>),
                                        (<Link key="3" to="/for-hyresgaster">För hyresgäster</Link>),
                                        (<Link key="4" to="/aktivitet">Aktivitet</Link>),
                                        (<Link key="5" to="/kontakt">Kontakt</Link>)
                                    ].map(e => {
                                        return (<li key={e.key} id={(e.key == this.props.active) ? "selected" : ""}>{e}</li>)
                                    })}
                                </ul>
                            </div>
                    </div>
                </header>
                <main style={{position: "relative"}}>
                    {this.props.children}
                </main>
                <Footer forcedBottom={this.props.forcedFooter}/>
            </div>
        )
    }
}
