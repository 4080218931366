import { Link } from 'gatsby'
import React, { Component } from 'react'
import { FaHome, FaPhone, FaEnvelope } from 'react-icons/fa'

export default class Footer extends Component<{forcedBottom?: boolean}> {
    render() {
        return (
            <footer style={{position: this.props.forcedBottom ? "absolute" : "initial", bottom: 0}} className="footer">
                    <div className="footer__divider p-margin">
                        <div className="footer__contact">
                            <h2>Kontakt</h2>
                            <ul>
                                <li><FaHome/> Address: <a target="_blank" href="https://www.google.com/maps/place/Bost%C3%A4llsv%C3%A4gen+6,+904+33+Ume%C3%A5/@63.8194198,20.2346481,17z/data=!4m13!1m7!3m6!1s0x467c5b9ddb202d87:0x3027c0dd85c9d40d!2zQm9zdMOkbGxzdsOkZ2VuIDYsIDkwNCAzMyBVbWXDpQ!3b1!8m2!3d63.8193817!4d20.2343676!3m4!1s0x467c5b9ddb202d87:0x3027c0dd85c9d40d!8m2!3d63.8193817!4d20.2343676">KaPaBo, Boställsvägen 6, 904 33 Umeå</a></li>
                                <li><FaPhone/> Telefon: <a href="tel:0704712549">070-4712549</a></li>
                                <li><FaEnvelope/> E-post: <a href="mailto:paessakonteh@hotmail.com">paessakonteh@hotmail.com</a></li>
                            </ul>
                        </div>
                        <div className="footer__pages">
                            <h2>Undersidor</h2>
                            <ul>
                                <li><Link to="/fastigheter">Fastigeheter</Link></li>
                                <li><Link to="/">För hyresgäster</Link></li>
                            </ul>
                        </div>
                    </div>
            </footer>
        )
    }
}
